<template>
  <div class="flex flex-col box items-center p-4">
    <section v-if="vista === 0" class="flex flex-col gap-4 w-1/2 p-4 justify-center">
      <div class="flex flex-col gap-2">
        <label>Tipo de Formato</label>
        <Dropdown
          v-model="values.TipoFormato"
          :options="TiposFormatos"
          optionLabel="nombre"
          optionValue="id"
          placeholder="Seleccione una opción"
          class="p-inputtext-xs"
        />
        <span class="text-red-500 text-xs">{{ errors.tipoId }}</span>
      </div>

      <div class="flex flex-col gap-2">
        <label>Tipo de solicitud</label>
        <Dropdown
          v-model="values.tipoId"
          :options="tiposSolicitud"
          optionLabel="nombre"
          optionValue="id"
          placeholder="Seleccione una opción"
          class="p-inputtext-xs"
          @change="onChangeTipoSolicitud"
        />
        <span class="text-red-500 text-xs">{{ errors.tipoId }}</span>
      </div>

      <div v-if="values.tipoId !== 1" class="flex flex-col gap-2">
        <label>Categoria</label>
        <Dropdown
          v-model="values.opcionProceso"
          :options="categorias"
          optionLabel="label"
          optionValue="value"
          placeholder="Seleccione una opción"
          class="p-inputtext-xs"
        />
        <span class="text-red-500 text-xs">{{ errors.opcionProceso }}</span>
      </div>
      <div class="flex flex-col gap-4" v-if="values.opcionProceso > 0">

        <div class="flex flex-col gap-2" v-if="values.opcionProceso > 0">
          <label>Tipo de procesos</label>
          <AutoComplete
            v-model="obj.tipoProceso.nombre"
            :suggestions="tiposProcesos"
            @complete="buscar($event, 1)"
            class="p-inputtext-xs"
            inputClass="w-full"
            field="nombre"
            @item-select="onChangeTipoProceso"
            placeholder="Digite el tipo de proceso"
            :forceSelection="values.tipoId !== 1"
          />
          <span class="text-xs text-red-500" v-if="errors.tipoProceso">{{ errors.tipoProceso }}</span>
        </div>

        <div class="flex flex-col gap-2" v-if="values.opcionProceso > 1">
          <label>Procesos</label>
          <AutoComplete
            v-model="obj.proceso.nombre"
            :suggestions="procesos"
            @complete="buscar($event, 2)"
            class="p-inputtext-xs"
            inputClass="w-full"
            field="nombre"
            @item-select="onChangeProceso"
            :disabled="obj.tipoProceso.nombre === ''"
            placeholder="Digite el proceso"
            :forceSelection="values.tipoId !== 1"
          />
          <span class="text-xs text-red-500" v-if="errors.proceso">{{ errors.proceso }}</span>
        </div>

        <div class="flex flex-col gap-2" v-if="values.opcionProceso > 2">
          <label>Carpetas</label>
          <AutoComplete
            v-model="obj.carpeta.nombre"
            :suggestions="carpetas"
            @complete="buscar($event, 3)"
            class="p-inputtext-xs"
            inputClass="w-full"
            field="nombre"
            @item-select="onChangeCarpeta"
            :disabled="obj.proceso.nombre === ''"
            placeholder="Digite la carpeta"
            :forceSelection="values.tipoId !== 1"
          />
          <span class="text-xs text-red-500" v-if="errors.carpeta">{{ errors.carpeta }}</span>
        </div>

        <div class="flex flex-col gap-2" v-if="values.opcionProceso > 2 && subcarpeta">
          <div class="flex">
            <label>SubCarpetas</label>
            <small class="ml-3">(Opcional)</small>
          </div>
          <AutoComplete
            v-model="obj.subcarpeta.nombre"
            :suggestions="subcarpetas"
            @complete="buscar($event, 5)"
            class="p-inputtext-xs"
            inputClass="w-full"
            field="nombre"
            @item-select="onChangeSubCarpeta"
            :disabled="obj.carpeta.nombre === ''"
            placeholder="Digite la subcarpeta"
            :forceSelection="values.tipoId !== 1"
          />
          <!-- <span class="text-xs text-red-500" v-if="errors.subcarpeta">{{ errors.subcarpeta }}</span> -->
        </div>

        <div class="flex flex-col gap-2" v-if="values.opcionProceso > 3">
          <label>Nombre del documento</label>
          <AutoComplete
            v-model="obj.documento.nombre"
            :suggestions="documentos"
            @complete="buscar($event, 4)"
            class="p-inputtext-xs"
            inputClass="w-full"
            field="nombre"
            @item-select="onChangeDocumento"
            :disabled="obj.carpeta.nombre === ''"
            placeholder="Digite el documento"
            :forceSelection="values.tipoId !== 1"
          />
          <span class="text-xs text-red-500" v-if="errors.documento">{{ errors.documento }}</span>
        </div>

        <div class="flex flex-col gap-2">
          <label>Area</label>
          <MultiSelect
            v-model="values.areas"
            :options="areas"
            optionLabel="name"
            placeholder="Seleccione un area"
            display="chip"
            class="multiselect-custom p-inputtext-xs text-xs"
          />
          <span class="text-red-500 text-xs">{{ errors.areas }}</span>
        </div>
      </div>
      <div class="flex flex-col gap-2 w-full">
        <label>Titulo</label>
        <InputText
          v-model="values.asunto"
          class="p-inputtext-xs"
          placeholder="Escriba el titulo de la solicitud"
        />
        <span class="text-red-500 text-xs">{{ errors.asunto }}</span>
      </div>
      <div class="flex flex-col gap-2 w-full">
        <label>Solicitud</label>
        <Textarea
          v-model="values.solicitud"
          rows="3"
          cols="30"
          class="p-inputtext-xs"
          placeholder="Escriba la solicitud"
        />
        <span class="text-red-500 text-xs">{{ errors.solicitud }}</span>
      </div>
    </section>
    <section v-if="vista === 1" class="flex w-full p-4">
      <Editor
        ref="editor"
        api-key="bk8rtsyvxlv71yhl5xdunqtik4gffjibzq2u0sh7iofxjvvz"
        :init="{
                plugins: 'lists link image   table',
                language: 'es',
                width: '100%',
              }"
        v-model="dataEditor"
      />
    </section>
    <section v-if="vista === 2" class="flex flex-col w-full p-4">
      <!-- <FormatoExcel v-if="values.TipoFormato == 2" ref="componentExcel"></FormatoExcel> -->
      <div v-if="values.TipoFormato == 2" class="flex flex-col gap-2 w-full">
        <div class="">
          <label>Cargar Formato</label>
          <button class="w-full">
            <FilePond ref="pond"/>
          </button>
        </div>
      </div>
    </section>
    <section class="flex justify-center gap-12">
      <template v-if="vista === 0">
        <Button label="Cancelar" icon="pi pi-times" @click="closeModal(1)" class="p-button-text"/>
      </template>
      <template v-if="vista === 1 || vista === 2">
        <Button label="Atras" icon="pi pi-arrow-left" @click="closeModal(0)" class="p-button-text"/>
      </template>
      <template v-if="btnSiguiente">
        <Button label="Siguiente" icon="pi pi-arrow-right" @click="onSubmit(1)"/>
      </template>
      <!-- <template v-if="!btnSiguiente && values.TipoFormato == 1">
        <Button label="Previsualizar" icon="pi pi-check" @click="previsualizar()"/>
      </template> -->
      <template v-if="!btnSiguiente">
        <Button label="Crear" icon="pi pi-check" @click="onSubmit(2)"/>
      </template>
    </section>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import { computed, onMounted, ref, watch } from 'vue'
import ModalSolicitudesService from '@/apps/pharmasan/calidad/views/mapa/services/modalSolicitudes.service'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'
import FilePondStore from '../filepond.store'
import FilePond from './filePound.vue'
// import FormatoExcel from './FormatoExcel.vue'

export default {
  name: 'crearSolicitud',
  components: { Editor, FilePond },
  setup (props, { emit }) {
    // Computed
    const adjunto = computed(
      () => FilePondStore.getters.adjunto
    )
    const ruta = computed(() => {
      if (obj.value.tipoProceso.nombre !== '') {
        let ruta = ''
        for (const [index, item] of Object.entries(obj.value)) {
          if (index === 'tipoProceso') {
            if (item.nombre !== '') {
              ruta += `${item.nombre}`
            }
          } else {
            if (item.nombre !== '') {
              ruta += ` > ${item.nombre}`
            }
          }
        }
        return ruta
      } else {
        return 'null'
      }
    })

    // Referencias
    const router = useRouter()

    // Services
    const _modalSolicitudesService = new ModalSolicitudesService()

    // Data
    const componentExcel = ref(null)
    const vista = ref(0)
    const btnSiguiente = ref(true)
    const showEditor = ref(false)
    const tamanoModal = ref('40vw')
    const tamanoForm = ref('w-full')
    const dataEditor = ref('')
    const areas = ref([])
    const selectedAreas = ref([])
    const tiposProcesos = ref([])
    const procesos = ref([])
    const carpetas = ref([])
    const subcarpetas = ref([])
    const documentos = ref([])
    const subcarpeta = ref(false)
    // const TipoFormato = ref(null)
    const pond = ref()
    const TiposFormatos = ref([{ id: 1, nombre: 'Procedimientos' }, { id: 2, nombre: 'Formatos' }])
    const cars = ref(
      [
        { brand: 'Volkswagen', year: 2012, color: 'Orange', vin: 'dsad231ff' },
        { brand: 'Audi', year: 2011, color: 'Black', vin: 'gwregre345' },
        { brand: 'Renault', year: 2005, color: 'Gray', vin: 'h354htr' },
        { brand: 'BMW', year: 2003, color: 'Blue', vin: 'j6w54qgh' },
        { brand: 'Mercedes', year: 1995, color: 'Orange', vin: 'hrtwy34' },
        { brand: 'Volvo', year: 2005, color: 'Black', vin: 'jejtyj' },
        { brand: 'Honda', year: 2012, color: 'Yellow', vin: 'g43gr' },
        { brand: 'Jaguar', year: 2013, color: 'Orange', vin: 'greg34' },
        { brand: 'Ford', year: 2000, color: 'Black', vin: 'h54hw5' },
        { brand: 'Fiat', year: 2013, color: 'Red', vin: '245t2s' }
    ]
    )
    const obj = ref({
      tipoProceso: {
        id: null,
        nombre: ''
      },
      proceso: {
        id: null,
        nombre: ''
      },
      carpeta: {
        id: null,
        nombre: ''
      },
      subcarpeta: {
        id: null,
        nombre: ''
      },
      documento: {
        id: null,
        nombre: ''
      }
    })
    const modalSolicitudes = ref(false)
    const tiposSolicitud = ref([])
    const categorias = [
      { label: 'Tipo Proceso', value: 1 },
      { label: 'Proceso', value: 2 },
      { label: 'Carpeta', value: 3 },
      { label: 'Documento', value: 4 }
    ]
    // tipoId
    // asunto
    // solicitud
    // padreId
    // path
    const schema = yup.object({
      areas: yup.array().nullable().required('El area es requerida'),
      tipoId: yup.number().nullable().required('El tipo de solicitud es requerido'),
      // TipoFormato: yup.number().nullable().required('El tipo de formato es requerido'),
      asunto: yup.string().nullable().required('El titulo es requerido'),
      solicitud: yup.string().nullable().required('La solicitud es requerida'),
      opcionProceso: yup.number().nullable().required('La categoria es requerida'),
      tipoProceso: yup.string().nullable().when('opcionProceso', (val, schema) => {
        if (val > 0) {
          return yup.string().nullable().required('El tipo de proceso es requerido').label('')
        } else {
          return yup.string().nullable(true)
        }
      }),
      proceso: yup.string().nullable().when('opcionProceso', (val, schema) => {
        if (val > 1) {
          return yup.string().nullable().required('El proceso es requerido').label('')
        } else {
          return yup.string().nullable(true)
        }
      }),
      carpeta: yup.string().nullable().when('opcionProceso', (val, schema) => {
        if (val > 2) {
          return yup.string().nullable().required('La carpeta es requerida').label('')
        } else {
          return yup.string().nullable(true)
        }
      }),
      documento: yup.string().nullable().when('opcionProceso', (val, schema) => {
        if (val > 3) {
          return yup.string().nullable().required('El documento es requerido').label('')
        } else {
          return yup.string().nullable(true)
        }
      })
    })
    const { errors, values, handleSubmit, handleReset, validate } = useForm({
      validationSchema: schema
    })
    useField('tipoId', null, { initialValue: null })
    useField('TipoFormato', null, { initialValue: null })
    useField('asunto', null, { initialValue: null })
    useField('solicitud', null, { initialValue: null })
    useField('opcionProceso', null, { initialValue: null })
    useField('tipoProceso', null, { initialValue: null })
    useField('proceso', null, { initialValue: null })
    useField('carpeta', null, { initialValue: null })
    useField('documento', null, { initialValue: null })
    useField('areas', null, { initialValue: null })

    // Computed

    // Methods
    const clear = () => {
      handleReset()
      obj.value = {
        tipoProceso: { id: null, nombre: '' },
        proceso: { id: null, nombre: '' },
        carpeta: { id: null, nombre: '' },
        documento: { id: null, nombre: '' }
      }
    }
    const openModal = () => {
      modalSolicitudes.value = true
    }
    const cambiarVista = (tipo) => {
      vista.value = tipo
      btnSiguiente.value = !btnSiguiente.value
    }
    const closeModal = (tipo = 0) => {
      if (vista.value === 1 || vista.value === 2) {
        cambiarVista(tipo)
      } else {
        router.push({ name: 'pharmasan.gestion.calidad.mapa-procesos.solicitudes' })
      }
      modalSolicitudes.value = false
      emit('llamarFuncion')
    }
    const alertar = (mensaje) => {
      Swal.fire({
        icon: 'warning',
        title: ` <span style="line-height: normal;">${mensaje}</span>`
      })
    }
    const openModalValidacion = () => {
      pond.value.onSubmitSupport().then(() => {
        console.log('procesando archivo...')
        if (Object.values(values).some(a => !a)) {
          alertar('Faltan campos por llenar')
        } else {
          if (adjunto.value.urlPath) {
            onSubmit(2)
          } else {
            alertar('Debe seleccionar un archivo')
          }
        }
      }).catch(err => {
        console.log(err.message)
      })
    }
    const onSubmit = async (tipo) => {
      if (tipo === 2) {
        const submit = handleSubmit(async (values) => {
          if (!values) throw new Error('Error de validación')
          let { areas } = values
          delete values.areas
          areas = areas.map((item) => {
            return item.id
          })
          if (values.TipoFormato === 1) {
            if (dataEditor.value === '') {
              Swal.fire({
                icon: 'warning',
                title: 'Advertencia',
                text: 'Debe ingresar el contenido del documento'
              })
              return
            }
          }
          var dataSolicitud = {
            TipoFormato: values.TipoFormato,
            tipoId: values.tipoId,
            solicitud: values.solicitud,
            asunto: values.asunto,
            ruta: ruta.value,
            path: dataEditor.value,
            areas: JSON.stringify(areas)
          }
          if (values.TipoFormato === 2) {
            // componentExcel.value.downloadExcel()
            await pond.value.onSubmitSupport().then(() => {
            console.log('procesando archivo...')
            }).catch(err => {
              console.log(err.message)
            })
            dataSolicitud.path = adjunto.value.urlPath
            dataSolicitud.tipo = adjunto.value.ext
          }
          _modalSolicitudesService.crearSolicitud(dataSolicitud).then((res) => {
            handleReset()
            router.push({ name: 'pharmasan.gestion.calidad.mapa-procesos.solicitudes' })
            Swal.fire({
              icon: 'success',
              title: 'Solicitud creada',
              text: 'La solicitud se ha creado correctamente'
            })
          })
        })
        await submit()
      } else {
        const { valid } = await validate()
        if (valid) cambiarVista(values.TipoFormato)
      }
    }
    const onChangeTipoSolicitud = () => {
      const temp = values.tipoId
      // clear()
      values.tipoId = temp
      if (values.tipoId === 1) {
        values.opcionProceso = 4
      }
    }
    const onChangeTipoProceso = ({ value }) => {
      values.padreId = value.id
      values.tipoProceso = value.nombre
      values.proceso = values.carpeta = values.documento = null

      obj.value = {
        tipoProceso: { id: value.id, nombre: value.nombre },
        proceso: { id: null, nombre: '' },
        carpeta: { id: null, nombre: '' },
        subcarpeta: { id: null, nombre: '' },
        documento: { id: null, nombre: '' }
      }
    }
    const onChangeProceso = ({ value }) => {
      values.proceso = value.nombre
      values.carpeta = values.documento = null

      obj.value = {
        tipoProceso: obj.value.tipoProceso,
        proceso: { id: value.id, nombre: value.nombre },
        carpeta: { id: null, nombre: '' },
        subcarpeta: { id: null, nombre: '' },
        documento: { id: null, nombre: '' }
      }
    }
    const onChangeCarpeta = ({ value }) => {
      values.carpeta = value.nombre
      values.documento = null
      obj.value = {
        tipoProceso: obj.value.tipoProceso,
        proceso: obj.value.proceso,
        carpeta: { id: value.id, nombre: value.nombre },
        subcarpeta: { id: null, nombre: '' },
        documento: { id: null, nombre: '' }
      }
    }
    const onChangeSubCarpeta = ({ value }) => {
      values.subcarpeta = value.nombre
      values.documento = null
      obj.value = {
        tipoProceso: obj.value.tipoProceso,
        proceso: obj.value.proceso,
        carpeta: obj.value.carpeta,
        subcarpeta: { id: value.id, nombre: value.nombre },
        documento: { id: null, nombre: '' }
      }
    }
    const onChangeDocumento = ({ value }) => {
      _modalSolicitudesService.obtenerHtml(value.id).then(({ data }) => {
        dataEditor.value = data.html
      })
      values.documento = value.nombre
      obj.value = {
        tipoProceso: obj.value.tipoProceso,
        proceso: obj.value.proceso,
        carpeta: obj.value.carpeta,
        documento: { id: value.id, nombre: value.nombre }
      }
    }
    const buscar = (event, tipoId) => {
      // Tipo 1 = Tipo de proceso, 2 = Proceso, 3 = Carpeta, 4 = Documento, 5 = subcarpeta
      switch (tipoId) {
        case 1:
          _modalSolicitudesService.getTiposProcesos({ nombre: event.query }).then((res) => {
            tiposProcesos.value = res.data
            if (values.tipoId === 1) {
              values.tipoProceso = event.query
              obj.value.tipoProceso = {
                id: null,
                nombre: event.query
              }
            }
          })
          break
        case 2:
          _modalSolicitudesService.getProcesos({
            nombre: event.query,
            padre: obj.value.tipoProceso.id
          }).then((res) => {
            procesos.value = res.data
            if (values.tipoId === 1) {
              values.proceso = event.query
              obj.value.proceso = {
                id: null,
                nombre: event.query
              }
            }
          })
          break
        case 3:
          _modalSolicitudesService.getCarpetas({
            nombre: event.query,
            padre: obj.value.proceso.id
          }).then((res) => {
            carpetas.value = res.data
            if (values.tipoId === 1) {
              values.carpeta = event.query
              obj.value.carpeta = {
                id: null,
                nombre: event.query
              }
            }
          })
          break
        case 4:
          if (values.tipoId !== 1) {
            _modalSolicitudesService.getDocumentos({
              nombre: event.query,
              padre: obj.value.carpeta.id
            }).then((res) => {
              documentos.value = res.data
            })
          } else {
            values.documento = event.query
            obj.value.documento = {
              id: null,
              nombre: event.query
            }
          }
          break
          case 5:
          _modalSolicitudesService.getCarpetas({
            nombre: event.query,
            padre: obj.value.carpeta.id
          }).then((res) => {
            subcarpetas.value = res.data
            if (values.tipoId === 1) {
              values.subcarpeta = event.query
              obj.value.subcarpeta = {
                id: null,
                nombre: event.query
              }
            }
          })
          break
      }
    }
    const previsualizar = () => {
      console.log('previsualizar')
    }

    // Watchers
    watch(() => obj.value.carpeta, (val) => {
      console.log('el hijo', carpetas.value.find(f => f.id === val.id))
      const carp = carpetas.value.find(f => f.id === val.id)
      if (carp) {
        if (carp.hijo.length) {
          subcarpeta.value = true
        }
      }
    })
    watch(() => values.tipoId, (val) => {
      btnSiguiente.value = val === 1
    })
    watch(() => values.opcionProceso, (val) => {
      btnSiguiente.value = val === 4
      // if (val === 4 ) {
      //
      // }
      if (val === 1) {
        obj.value = {
          tipoProceso: { ...obj.value.tipoProceso },
          proceso: { id: null, nombre: '' },
          carpeta: { id: null, nombre: '' },
          documento: { id: null, nombre: '' }
        }
      } else if (val === 2) {
        obj.value.carpeta = { id: null, nombre: '' }
        obj.value.documento = { id: null, nombre: '' }
      } else if (val === 3) {
        obj.value.documento = { id: null, nombre: '' }
      }
    })

    // OnMounted
    onMounted(() => {
      _modalSolicitudesService.getTiposSolicitud().then((res) => {
        tiposSolicitud.value = res.data
      })
      _modalSolicitudesService.getAreasList().then((res) => {
        areas.value = res.data
      })
    })

    return {
      pond,
      cars,
      TiposFormatos,
      modalSolicitudes,
      openModal,
      closeModal,
      errors,
      values,
      onSubmit,
      tiposSolicitud,
      obj,
      tiposProcesos,
      procesos,
      carpetas,
      subcarpetas,
      subcarpeta,
      documentos,
      onChangeTipoProceso,
      onChangeProceso,
      onChangeCarpeta,
      onChangeSubCarpeta,
      onChangeDocumento,
      onChangeTipoSolicitud,
      openModalValidacion,
      buscar,
      clear,
      previsualizar,
      dataEditor,
      tamanoModal,
      showEditor,
      tamanoForm,
      categorias,
      ruta,
      btnSiguiente,
      vista,
      areas,
      selectedAreas,
      componentExcel
    }
  }
}
</script>

<style scoped>

</style>
